<template>
  <div class="why-bnox-section">
    <div class="container">
      <div class="content-wrapper d-flex flex-wrap">
        <h1 class="title headline-l">{{ $t('bnoxScreen.comparison.title') }}</h1>
        <div class="img-table-wrapper">
          <img :src="`/images/sections/bnox/table_${$i18n.locale}.svg`" class="table-img">
        </div>
        <!-- <div class="table-wrapper" ref="tableWrapper">
          <div class="table">
            <div class="table-header">
              <div />
              <div class="table-header-cell" v-for="(title, index) in tableHeaders" :key="index">
                {{ title }}
              </div>
            </div>
            <div class="table-content">
              <div class="table-row-cell" v-for="(rowCell, index) in tableRows" :key="index">
                <div class="cell title">{{ rowCell.title }}</div>
                <div :class="['cell bnox', rowCell.bnox.class]">
                  <span v-if="typeof rowCell.bnox === 'object'">{{ rowCell.bnox.text }}</span>
                  <span v-else>{{ rowCell.bnox }}</span>
                </div>
                <div :class="['cell physical-gold', rowCell.physicalGold.class]">
                  <span v-if="typeof rowCell.physicalGold === 'object'">
                    {{ rowCell.physicalGold.text }}
                  </span>
                  <span v-else>{{ rowCell.physicalGold }}</span>
                </div>
                <div :class="['cell financial-products', rowCell.financialProducts.class]">
                  <span v-if="typeof rowCell.financialProducts === 'object'">
                    {{ rowCell.financialProducts.text }}
                  </span>
                  <span v-else>{{ rowCell.financialProducts }}</span>
                </div>
                <div :class="['cell stablecoins', rowCell.stablecoins.class]">
                  <span v-if="typeof rowCell.stablecoins === 'object'">
                    {{ rowCell.stablecoins.text }}
                  </span>
                  <span v-else>{{ rowCell.stablecoins }}</span>
                </div>
                <div :class="['cell gold-tokens', rowCell.goldTokens.class]">
                  <span v-if="typeof rowCell.goldTokens === 'object'">
                    {{ rowCell.goldTokens.text }}
                  </span>
                  <span v-else>{{ rowCell.goldTokens }}</span>
                </div>
              </div>
              <div class="table-footer">
                {{ $t('bnoxScreen.comparison.table.footer') }}
              </div>
            </div>
          </div>
          <div :class="['swipe-indicator', { 'hidden': !isSwipeIndicatorVisible }]">
            <img class="swipe-icon" src="/images/icons/swipe-left.svg" >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WhyBnox',
  data() {
    return {
      isSwipeIndicatorVisible: true,
    };
  },
  computed: {
    trueValueText() {
      return {
        text: this.$t('bnoxScreen.comparison.table.trueValue'),
        class: 'green',
      };
    },
    falseValueText() {
      return {
        text: this.$t('bnoxScreen.comparison.table.falseValue'),
        class: 'purple',
      };
    },
    notApplicableText() {
      return this.$t('bnoxScreen.comparison.table.notApplicable');
    },
    variousStandardsText() {
      return this.$t('bnoxScreen.comparison.table.variousStandards');
    },
    variousTransparencyText() {
      return this.$t('bnoxScreen.comparison.table.variousTransparency');
    },
    notCommonText() {
      return this.$t('bnoxScreen.comparison.table.notCommon');
    },
    variousText() {
      return this.$t('bnoxScreen.comparison.table.various');
    },
    instantText() {
      return {
        text: this.$t('bnoxScreen.comparison.table.instant'),
        class: 'green bigger',
      };
    },
    highText() {
      return {
        text: this.$t('bnoxScreen.comparison.table.high'),
        class: 'dark-purple',
      };
    },
    lowText() {
      return {
        text: this.$t('bnoxScreen.comparison.table.low'),
        class: 'dark-purple',
      };
    },
    tableHeaders() {
      return [
        this.$t('bnoxScreen.comparison.table.header.bnox'),
        this.$t('bnoxScreen.comparison.table.header.physicalGold'),
        this.$t('bnoxScreen.comparison.table.header.goldDerivatives'),
        this.$t('bnoxScreen.comparison.table.header.stablecoins'),
        this.$t('bnoxScreen.comparison.table.header.goldTokens'),
      ];
    },
    tableRows() {
      return [
        {
          title: this.$t('bnoxScreen.comparison.table.rows.regulated'),
          bnox: this.trueValueText,
          physicalGold: this.falseValueText,
          financialProducts: this.trueValueText,
          stablecoins: this.variousStandardsText,
          goldTokens: this.variousStandardsText,
        },
        {
          title: this.$t('bnoxScreen.comparison.table.rows.dedicated'),
          bnox: this.trueValueText,
          physicalGold: this.falseValueText,
          financialProducts: this.falseValueText,
          stablecoins: this.notApplicableText,
          goldTokens: this.falseValueText,
        },
        {
          title: this.$t('bnoxScreen.comparison.table.rows.thirdParty'),
          bnox: this.trueValueText,
          physicalGold: this.falseValueText,
          financialProducts: this.trueValueText,
          stablecoins: this.variousTransparencyText,
          goldTokens: this.notCommonText,
        },
        {
          title: this.$t('bnoxScreen.comparison.table.rows.redeem'),
          bnox: this.trueValueText,
          physicalGold: this.trueValueText,
          financialProducts: this.falseValueText,
          stablecoins: this.notApplicableText,
          goldTokens: this.notCommonText,
        },
        {
          title: this.$t('bnoxScreen.comparison.table.rows.fraud'),
          bnox: this.highText,
          physicalGold: this.lowText,
          financialProducts: this.highText,
          stablecoins: this.variousText,
          goldTokens: this.variousText,
        },
        // {
        //   title: this.$t('landing.sections.whyBnox.table.rows.fraud.title'),
        //   bnox: this.trueValueText,
        //   physicalGold: this.trueValueText,
        //   financialProducts: this.trueValueText,
        //   stablecoins: this.trueValueText,
        //   goldTokens: this.falseValueText,
        // },
        {
          title: this.$t('bnoxScreen.comparison.table.rows.time'),
          bnox: this.instantText,
          physicalGold: this.$t('bnoxScreen.comparison.table.rows.physicalGold'),
          financialProducts: this.trueValueText,
          stablecoins: this.instantText,
          goldTokens: this.instantText,
        },
      ];
    },
  },
  methods: {
    handleTableScroll() {
      this.isSwipeIndicatorVisible = false;
    },
  },
  mounted() {
    if(this.$refs.tableWrapper) {
      this.$refs.tableWrapper.addEventListener('scroll', this.handleTableScroll);
    }
  },
  beforeDestroy() {
    if(this.$refs.tableWrapper) {
      this.$refs.tableWrapper.removeEventListener('scroll', this.handleTableScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.why-bnox-section {
  padding: 95px 0 50px;

  & > .container {
    max-width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 575px) {
    padding-top: 40px;
  }
}

.content-wrapper {
  .title {
    color: $primary-purple;
    width: 100%;
    text-align: center;
    margin: 0 0 60px;
  }
}

.content-wrapper .table {
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px -10px rgba(105,119,135,0.2), 0 20px 30px -10px rgba(56,56,56,0.05);
  width: 820px;
  margin: 0 auto;

  @media screen and (max-width: 991px) {
    width: 780px;
  }

  .table-header {
    display: grid;
    grid-template-columns: auto repeat(5, 130px);
    height: 70px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 130px repeat(5, 130px);
    }

    .table-header-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      font-family: 'Muli-Bold';

      &:nth-child(2) {
        border-radius: 3px 3px 0 0;
        background: radial-gradient(circle at left, #FFFFB1 -73%,
          #D39E6D 55.11%, #7F5E45 130.95%, #5C4334 100%);
        text-transform: uppercase;
        color: #fff;
        font-size: 24px;
      }

      &:nth-child(3) {
        border-radius: 3px 3px 0 0;
        margin-left: -3px;
      }

      &:nth-child(n+3) {
        background-color: #F2F2F2;
        font-size: 12px;
        color: #1D1E3F;
        padding: 0 25px;
      }

      &:nth-child(4) {
        padding: 0 0 0 15px;
      }
    }
  }
}

.table-header {
  border-bottom: 1px solid #E3E8ED;
}

.table-content {
  display: grid;
  grid-template-rows: repeat(6, 60px);
}

.table-row-cell {
  display: grid;
  grid-template-columns: auto repeat(5, 130px);

  @media screen and (max-width: 991px) {
    grid-template-columns: 130px repeat(5, 130px);
  }

  &:first-child,
  &:nth-last-child(2),
  &:nth-child(5) {
    grid-template-columns: auto repeat(3, 130px) 260px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 130px repeat(3, 130px) 260px;
    }

    .cell:last-child {
      display: none;
    }
  }

  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D1E3F;
    font-family: 'Muli-Bold';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-right: 1px solid #E3E8ED;
    border-bottom: 1px solid #E3E8ED;

    &:nth-child(2) {
      width: calc(100% - 3px); // beacouse of the table header's border radius
    }

    &.green span,
    &.purple span {
      color: #fff;
      border-radius: 3px;
      width: 60px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
    }

    &.green.bigger span {
      width: 100px;
    }

    &.green span {
      background-color: #00DDA9;
    }

    &.purple span {
      background-color: #A899B7;
    }

    &.dark-purple span {
      background-color: #1D1E3F;
      color: #fff;
      width: 60px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
    }
  }

  .title {
    font-size: 14px;
    color: #3C3C3B;
    line-height: 18px;
    font-family: 'Muli-Regular';
    justify-content: flex-start;
    text-align: left;
    padding: 0 28px 0 30px;
    margin: 0;

    @media screen and (max-width: 991px) {
      padding: 0 5px 0 20px;
    }
  }
}

.table-footer {
  // height: 120px;
  padding: 35px 70px;
  color: #BCBEC6;
  font-size: 10px;
  line-height: 16px;
  font-family: 'Muli-Bold';
}

.table-wrapper {
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 991px) {
    overflow: scroll;
  }
}

.swipe-indicator {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  background: #1D1E3F;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  padding: 15px;

  &.hidden {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: block;
  }

  .swipe-icon {
    width: 100%;
    height: auto;
    filter: invert(100%);
  }
}


.img-table-wrapper {
  display: block;
  text-align: center;
  width: 100%;

  @media (max-width: $breakpoint-sm) {
    overflow-x: scroll;
  }

  .table-img {
    margin: 0 auto;

    @media (max-width: $breakpoint-sm) {
      min-width: 665px;
    }
  }
}
</style>
