import { render, staticRenderFns } from "./TokenInformationSection.vue?vue&type=template&id=66f74256&scoped=true&"
import script from "./TokenInformationSection.vue?vue&type=script&lang=js&"
export * from "./TokenInformationSection.vue?vue&type=script&lang=js&"
import style0 from "./TokenInformationSection.vue?vue&type=style&index=0&id=66f74256&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f74256",
  null
  
)

export default component.exports