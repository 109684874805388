<template>
  <div class="token-information-section" :style="`--background: ${background}`">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoxScreen.tokenInformation.title") }}</h1>
      <bordered-box borderColor="#F59A14" boxShadow="0px 4px 8px rgba(75, 46, 170, 0.1)">
        <template v-slot:description>
          <ul class="list left-col">
            <li v-for="(key, i) in informationKeysLeft" :key="i">
              <span class="label text-l">{{ $t(`bnoxScreen.tokenInformation.list.${key}.label`) }}</span>
              <span class="value text-l">{{ $t(`bnoxScreen.tokenInformation.list.${key}.value`) }}</span>
            </li>
          </ul>
          <ul class="list right-col">
            <li v-for="(key, i) in informationKeysRight" :key="i">
              <span class="label text-l">{{ $t(`bnoxScreen.tokenInformation.list.${key}.label`) }}</span>
              <span class="value text-l">{{ $t(`bnoxScreen.tokenInformation.list.${key}.value`) }}</span>
            </li>
          </ul>
        </template>
      </bordered-box>
      <div class="button-row">
        <base-button size="large" :text="$t('bnoxScreen.tokenInformation.buttonText')" @clicked="handleButtonClick()" />
      </div>
    </div>
  </div>
</template>

<script>
import BorderedBox from "@/components/elements/BorderedBox";
import BaseButton from "@/components/elements/Button";

export default {
  name: "TokenInformationSection",
  props: {
    background: {
      type: String,
      required: false,
      default: "#F6F4FF"
    }
  },
  components: {
    BorderedBox,
    BaseButton
  },
  data: () => ({
    informationKeysLeft: ["type", "symbol", "emission", "production", "compatibility"],
    informationKeysRight: ["transferFee", "account", "smartContract"]
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.open("https://github.com/blockben-official/bnox", "_blank");
    }
  }
}
</script>
 
<style lang='scss' scoped>
.token-information-section {
  background: var(--background);
  padding: 50px 0 70px;
}

.title {
  margin: 0 0 60px;
  text-align: center;
  color: $primary-purple;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 45px;
  }
}

.bordered-box {
  border-top: 1px solid $light-purple;
  border-right: 1px solid $light-purple;
  border-bottom: 1px solid $light-purple;
  max-width: 935px;
  margin: 0 auto;

  @media (max-width: $breakpoint-md) {
    max-width: none;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 40px 20px;
  }

  &::v-deep {
    .description-wrapper {
      display: flex;

      @media (max-width: $breakpoint-sm) {
        flex-wrap: wrap;
      }
    }
  }

  .list {
    width: 50%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    li {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .label {
    display: block;
    font-family: "HKNova-Bold", sans-serif;
  }

  .value {
    word-wrap: break-word;
  }
}

.button-row {
  text-align: center;
  margin: 60px 0 0;
}
</style>