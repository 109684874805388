<template>
  <div class="bnox-trade-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoxScreen.bnoxTrade.title") }}</h1>
      <div class="row purchase-row">
        <div class="col-12">
          <h4 class="title">
            {{ $t("bnoxScreen.bnoxTrade.blocks.purchase.title") }}
          </h4>
        </div>
        <div class="col-12 col-lg-6">
          <bordered-box
            class="ml-lg-auto"
            :title="
              $t('bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.title')
            "
            :borderColor="'#F59A14'"
            :boxShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
          >
            <template v-slot:description>
              <span class="label">{{
                $t("bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.label")
              }}</span>
              <ul class="list">
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.steps.registration"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.steps.topUp"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.steps.buy"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.steps.change"
                    )
                  }}
                </li>
              </ul>
              <div class="stores">
                <store-button version="apple" />
                <store-button version="google" />
              </div>
              <router-link to="/wallet" class="more-link">{{
                $t(
                  "bnoxScreen.bnoxTrade.blocks.purchase.withinBlockben.moreText"
                )
              }}</router-link>
            </template>
          </bordered-box>
        </div>
        <div class="col-12 col-lg-6">
          <bordered-box
            :title="
              $t('bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.title')
            "
            :borderColor="'#F59A14'"
            :boxShadow="'0px 4px 8px rgba(75, 46, 170, 0.1)'"
          >
            <template v-slot:description>
              <span class="label">{{
                $t("bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.label")
              }}</span>
              <ul class="list">
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.steps.visit"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.steps.choose"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.steps.buy"
                    )
                  }}
                </li>
              </ul>
              <div class="button-row">
                <base-button
                  size="large"
                  :text="$t('bnoxScreen.bnoxTrade.blocks.purchase.stockExchanges.buttonText')"
                  @clicked="handleButtonClick()"
                />
              </div>
            </template>
          </bordered-box>
        </div>
      </div>
      <div class="row transfer-row align-items-center">
        <div class="col-md-6 mb-4 mb-md-0 d-none d-md-block">
          <img
            style="width: 80%; margin: auto"
            src="/images/sections/bnox/utalas.png"
            class="illustration"
          />
        </div>
        <div class="col-12 d-sm-none nopadding">
          <img src="/images/sections/bnox/utalas.png" class="illustration" />
        </div>

        <div class="col-12 col-lg-6 content-col">
          <h1 class="title headline-l">
            {{ $t("bnoxScreen.bnoxTrade.blocks.transfer.title") }}
          </h1>
          <div class="content text-l">
            {{ $t("bnoxScreen.bnoxTrade.blocks.transfer.description") }}
          </div>
          <small class="note">{{
            $t("bnoxScreen.bnoxTrade.blocks.transfer.note")
          }}</small>
        </div>
      </div>
      <div class="row selling-row align-items-center">
        <div class="col-md-6 mb-4 mb-md-0 d-none d-md-block">
          <img
            style="width: 80%; margin: auto"
            src="/images/sections/bnox/eladas.png"
            class="illustration"
          />
        </div>
        <div class="col-12 d-sm-none nopadding">
          <img src="/images/sections/bnox/eladas.png" class="illustration" />
        </div>
        <div class="col-12 col-lg-6 content-col">
          <h1 class="title headline-l">
            {{ $t("bnoxScreen.bnoxTrade.blocks.selling.title") }}
          </h1>
          <div
            class="content text-l"
            v-html="$t('bnoxScreen.bnoxTrade.blocks.selling.description')"
          />
          <ul class="text-l line-break">
            <li v-html="$t('bnoxScreen.bnoxTrade.blocks.selling.list.first')" />
            <li
              v-html="$t('bnoxScreen.bnoxTrade.blocks.selling.list.second')"
            />
            <li v-html="$t('bnoxScreen.bnoxTrade.blocks.selling.list.third')" />
          </ul>
          <small
            class="note"
            v-html="$t('bnoxScreen.bnoxTrade.blocks.selling.list.thirdNote')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BorderedBox from "@/components/elements/BorderedBox";
import StoreButton from "@/components/elements/StoreButton";
import BaseButton from "@/components/elements/Button";

export default {
  name: "BnoxTradeSection",
  props: {},
  components: {
    BorderedBox,
    StoreButton,
    BaseButton,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      let el = document.querySelector(".partners-section");
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  },
};
</script>
 
<style lang='scss' scoped>
.bnox-trade-section {
  padding: 100px 0;

  @media (max-width: $breakpoint-sm) {
    padding: 80px 0;
  }
}

.title {
  color: $primary-purple;
  margin: 0 0 65px;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 20px;
  }
}

.purchase-row {
  .col-lg-6 {
    &:nth-last-child(2) {
      @media (max-width: $breakpoint-md) {
        margin: 0 0 30px;
      }
    }
  }

  .title {
    color: $dark-grey;
    font-family: "HKNova-Bold", sans-serif;
    display: block;
    margin: 0 0 25px;
    line-height: 35px;
    font-size: 24px;
  }

  .bordered-box {
    border-top: 1px solid $light-purple;
    border-right: 1px solid $light-purple;
    border-bottom: 1px solid $light-purple;
    height: 100%;
    padding-left: 50px;
    padding-right: 35px;
    max-width: 460px;

    @media (max-width: $breakpoint-md) {
      margin: 0 auto;
    }

    @media (max-width: $breakpoint-sm) {
      padding-left: 25px;
      padding-right: 25px;
    }

    &::v-deep {
      .title {
        text-transform: uppercase;
        margin: 0 0 25px;
      }

      .description-wrapper {
        color: $text-blue;
        font-size: 16px;
        line-height: 30px;

        .list {
          padding: 0 0 0 25px;
          margin: 0 0 50px;

          @media (max-width: $breakpoint-sm) {
            margin: 0 0 40px;
          }
        }

        .label {
          font-family: "HKNova-Bold", sans-serif;
        }
      }

      .stores {
        display: flex;
        margin: 0 0 30px;

        @media (max-width: $breakpoint-sm) {
          display: block;
        }

        .apple-button,
        .google-button {
          @media (max-width: $breakpoint-sm) {
            width: 180px;
            margin: 0 auto;
          }

          img {
            @media (max-width: $breakpoint-sm) {
              margin: 0 auto;
            }
          }
        }

        .apple-button {
          margin-right: 20px;

          @media (max-width: $breakpoint-sm) {
            margin: 0 auto 30px;
          }
        }
      }

      .more-link {
        color: $text-blue;
        font-size: 14px;
        line-height: 30px;
        font-family: "HKNova", sans-serif;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.illustration-col {
  .illustration-wrapper {
    img {
      margin-left: auto;
      margin-right: 125px;

      @media (max-width: $breakpoint-md) {
        margin: 0 auto 40px;
      }
    }
  }
}

.content-col {
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: $breakpoint-sm) {
    margin-top: 50px;
  }

  .title {
    text-align: left;
    margin: 0 0 30px;
  }

  .note {
    font-size: 12px;
    line-height: 24px;
    margin: 20px 0 0;
    color: $text-blue;
    display: block;
  }

  .content {
    &::v-deep {
      b {
        font-family: "HKNova-Bold", sans-serif;
        font-weight: 700;
      }
    }
  }

  ul {
    margin: 45px 0 0;
    padding: 0 0 0 20px;

    li {
      @media (max-width: $breakpoint-md) {
        margin: 0 0 25px;
      }
    }
  }
}

.transfer-row {
  margin-top: 120px;
  margin-bottom: 100px;

  @media (max-width: $breakpoint-sm) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.button-row {
  @media (max-width: $breakpoint-md) {
    text-align: center;
  }
}
.line-break{
  word-break: break-word;
}
</style>