<template>
  <div class="static-chart">
    <div class="chart-wrapper">
      <div class="top-section">
        <h1 class="title">{{ title }}</h1>
        <div class="lines">
          <img :src="linesSrc" >
        </div>
        <ul class="scales">
          <li v-for="(item, i) in scales" :key="i">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="bottom-section">
        <div class="labels">
          <span v-if="leftSideLabelText" class="label label-left">{{ leftSideLabelText }}</span>
          <span v-if="rightSideLabelText" class="label label-right">{{ rightSideLabelText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StaticChart",
  props: {
    title: {
      type: String
    },
    linesSrc: {
      type: String
    },
    scales: {
      type: Array
    },
    leftSideLabelText: {
      type: String,
      required: false,
    },
    rightSideLabelText: {
      type: String,
      required: false,
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.chart-wrapper {
  border-radius: 10px;
  border: $light-purple;
  background: linear-gradient(136.92deg, #FFFFFF -4.15%, rgba(253, 253, 253, 0.942708) 42.63%, rgba(243, 243, 243, 0.670937) 72.66%, rgba(219, 218, 218, 0) 106.74%);
  border: 1px solid $light-purple;
  box-shadow: 0px 4px 8px rgba(75, 46, 170, 0.1);
}

.top-section {
  padding: 20px 17px 0;

  .title {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-family: "HKNova-Bold", sans-serif;
    font-weight: 700;
    color: $dark-grey;
    min-height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 20px 0 30px;
    list-style-type: none;

    li {
      color: $text-blue;
    }
  }
}

.bottom-section {
  padding: 0 0 37px;
}

.lines {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
}

.labels {
  display: flex;
  justify-content: space-between;

  .label {
    text-align: center;
    font-size: 16px;
    line-height: 43px;
    width: 108px;

    @media (max-width: $breakpoint-xs) {
      width: calc(50% - 2.5px);
    }
  }

  .label-left {
    border-radius: 0 10px 10px 0;
    background: $blockstock-yellow;
    color: $text-blue;
  }

  .label-right {
    border-radius: 10px 0 0 10px;
    background: $primary-purple;
    color: $white;
  }
}
</style>