<template>
  <div class="mentions-section">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoxScreen.mentions.title") }}</h1>
      <div class="row article-row">
        <div class="col-12 col-md-6 nopadding">
          <img src="/images/sections/bnox/rolunk_irtak.jpg" class="thumbnail">
        </div>
        <div class="col-12 col-md-6">
          <div class="article-content">
            <h3 class="title">{{ $t("bnoxScreen.mentions.article.title") }}</h3>
            <p class="description text-l">{{ $t("bnoxScreen.mentions.article.description") }}</p>
            <a href="https://thefinancialtechnology.com/blockchain-technology/blockchain-firm-blockben-creates-gold-backed-crypto-on-ethereum-platform/" target="_blank" class="read-more">{{ $t("bnoxScreen.mentions.article.readMore") }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentionsSection",
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.mentions-section {
  padding: 80px 0 50px;

  @media (max-width: $breakpoint-sm) {
    padding: 60px 0 40px;
  }
}

.title {
  color: $primary-purple;
  margin: 0 0 40px;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 20px;
  }
}

.thumbnail {
  margin: 0 auto;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 50px;
    width: 100%;
  }
}

.article-content {
  .title,
  .description {
    max-width: 440px;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
      max-width: none;
    }
  }

  .title {
    text-align: left;
    font-family: "HKNova-Bold", sans-serif;
    font-size: 24px;
    line-height: 35px;
    margin: 0 0 25px;
    color: $dark-grey;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }

  .read-more {
    display: flex;
    align-items: center;
    margin: 40px 0 0;

    @media (max-width: $breakpoint-sm) {
      justify-content: center;
    }

    &:hover {
      &:after {
        background: $link-hover-color;
      }
    }

    &:after {
      content: "";
      width: 10px;
      height: 17px;
      transition: $transition-global;
      background: $primary-purple;
      display: inline-block;
      mask-image: url("/images/icons/bb-icon_arrow-right.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      margin: 0 0 0 8px;
    }
  }
}

</style>