<template>
  <div class="introduction-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-4 mb-md-0 d-none d-md-block">
          <img
            style="width: 80%; margin: auto"
            src="/images/sections/bnox/miert.png"
            class="illustration"
          />
        </div>
        <div class="col-12 d-sm-none nopadding">
          <img src="/images/sections/bnox/miert.png" class="illustration" />
        </div>
        <div class="col-12 col-lg-6 content-col">
          <h1 class="title headline-l">
            {{ $t("bnoxScreen.introduction.title") }}
          </h1>
          <span class="description text-l">{{
            $t("bnoxScreen.introduction.description")
          }}</span>
        </div>
      </div>
      <div class="row blocks-row">
        <div v-for="(block, i) in blocks" :key="i" class="col-12 col-md-6">
          <block
            :iconSrc="block.iconSrc"
            :title="$t(block.title)"
            :description="$t(block.description)"
          />
        </div>
      </div>
      <div class="row audits-row">
        <div class="col-12">
          <h1 class="title headline-l">{{ $t("bnoxScreen.goldAudit.title") }}</h1>
        </div>
        <template v-for="(audit, i) in audits">
          <div class="col-audit col-12 col-sm-3" :key="i">
            <div class="content-box">
              <img src="/images/icons/bb-icon_document-search.svg" class="icon">
              <span class="label">{{ audit.date }}</span>
              <a :href="audit.assetUrl" class="download-btn">{{ $t("ebsoScreen.goldAudit.downloadButtonText") }}</a>
            </div>
          </div>
        </template>
      </div>
      <div class="row video-row">
        <div class="col-12">
          <div class="video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/cMbCvgmmt10"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              v-if="this.$i18n.locale === 'hu'"
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/Uq6QAZxzsrE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              v-if="this.$i18n.locale === 'en'"
            ></iframe>
          </div>
        </div>
        <div class="col-12 text-center">
          <a href="#" class="more-videos-btn">{{
            $t("bnoxScreen.introduction.moreVideosButton")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/elements/Button";
import Block from "@/components/modules/bnox/Block";

export default {
  name: "IntroductionSection",
  props: {},
  components: {
    BaseButton,
    Block,
  },
  data: () => ({
    blocks: [
      {
        iconSrc: "/images/icons/bb-icon_document-search.svg",
        title: "bnoxScreen.introduction.blocks.audit.title",
        description: "bnoxScreen.introduction.blocks.audit.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_search.svg",
        title: "bnoxScreen.introduction.blocks.issuer.title",
        description: "bnoxScreen.introduction.blocks.issuer.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_user-gold.svg",
        title: "bnoxScreen.introduction.blocks.gold.title",
        description: "bnoxScreen.introduction.blocks.gold.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_kyc.svg",
        title: "bnoxScreen.introduction.blocks.kyc.title",
        description: "bnoxScreen.introduction.blocks.kyc.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_wallet.svg",
        title: "bnoxScreen.introduction.blocks.fiat.title",
        description: "bnoxScreen.introduction.blocks.fiat.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_protection.svg",
        title: "bnoxScreen.introduction.blocks.protection.title",
        description: "bnoxScreen.introduction.blocks.protection.description",
      },
      {
        iconSrc: "/images/icons/bb-icon_refund.svg",
        title: "bnoxScreen.introduction.blocks.refund.title",
        description: "bnoxScreen.introduction.blocks.refund.description",
      },
    ],
    audits: [
      {
        date: "2020.04.22",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2020.04.07.pdf"
      },
      {
        date: "2020.10.07",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2020.09.30.pdf"
      },
      {
        date: "2021.04.09",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2021.03.16.pdf"
      },
      {
        date: "2022.12.21",
        assetUrl: "https://data.blockben.com/AuAudit/Certification_BlockBen_2022.12.22.pdf"
      },
    ],
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
.introduction-section {
  padding: 45px 0 75px;

  @media (max-width: $breakpoint-sm) {
    padding-bottom: 50px;
  }
}

.content-col {
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: $breakpoint-sm) {
    margin-top: 50px;
  }
}
.headline-l {
  color: $primary-purple;
  margin: 0 0 25px;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

.description {
  display: block;

  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

.blocks-row {
  margin-top: 75px;
}

.video-row {
  margin-top: 95px;
}

.video-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.more-videos-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px auto 0;
  color: $primary-purple;
  font-family: "HKNova", sans-serif;

  @media (max-width: $breakpoint-sm) {
    margin: 50px auto 0;
  }

  &:after {
    content: "";
    width: 10px;
    height: 15px;
    display: inline-block;
    background-color: $primary-purple;
    mask-image: url("/images/icons/bb-icon_arrow-right.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    margin: 3px 0 0 10px;
    transition: $transition-global;
  }

  &:hover {
    &:after {
      background-color: $link-hover-color;
    }
  }
}

.audits-row {
  margin: 130px 0 130px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-xs) {
    margin: 0 0 55px;
  }

  .title {
    text-align: center;
    margin: 0 0 60px;
  }

  .col-audit {
    @media (max-width: $breakpoint-xs) {
      margin: 0 0 80px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.content-box {
  text-align: center;

  .icon {
    margin: 0 auto 20px;
    max-height: 40px;
  }

  .icon-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .label {
    display: block;
    font-family: "HKNova-Bold", sans-serif;
    margin: 0 0 15px;
  }

  .download-btn {
    font-family: "HKNova", sans-serif;
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>