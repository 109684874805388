<template>
  <div class="block">
    <div class="icon-wrapper">
      <img :src="iconSrc">
    </div>
    <div class="content-wrapper">
      <span class="title">{{ title }}</span>
      <span class="description text-l">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Block",
  props: {
    title: {
      type: String
    },
    description: {
      type: String,
    },
    iconSrc: {
      type: String
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.block {
  padding: 0 20px 50px 0;
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    padding-right: 0;
  }

  .icon-wrapper {
    flex: 1 0 auto;
    min-width: 60px;
    margin-right: 15px;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      margin: 0 0 25px;
    }
    
    img {
      margin: 0 auto;
    }
  }

  .title {
    display: block;
    margin: 0 0 15px;
    min-height: 65px;
    color: $dark-grey;
    font-size: 24px;
    line-height: 35px;
    font-family: "HKNova-Bold", sans-serif;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
      min-height: 0;
    }
  }

  .description {
    @media (max-width: $breakpoint-sm) {
      display: block;
      text-align: center;
    }
  }
}

.content-wrapper {
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
</style>