<template>
  <div
    class="quote-section"
    :style="`--borderColor: ${borderColor};--backgroundColor: ${backgroundColor};--textColor: ${textColor};`"
  >
    <div class="container text-center">
      <span class="quote">{{ quote }}</span>
      <span v-if="author" class="author">{{ author }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Quote",
  props: {
    quote: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: false,
    },
    borderColor: {
      type: String,
      required: false,
      default: "#FCBB11",
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#000000",
    },
    textColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
.quote-section {
  color: $dark-grey;
  padding: 55px 0 40px;
  border-bottom: 1px solid var(--borderColor);
  background-color: var(--backgroundColor);
  color: var(--textColor);
}

.quote,
.author {
  display: block;
}

.quote {
  font-family: "HKNova-Bold", sans-serif;
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 35px;

  @media (max-width: $breakpoint-xs) {
    padding: 0 50px;
    text-align: center;
  }
}

.author {
  font-size: 16px;
  line-height: 24px;

  &:before {
    content: "-";
    padding: 0 5px 0 0;
  }
}
</style>