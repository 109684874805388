<template>
  <div class="digital-gold-section" :style="`--background: ${background}`">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoxScreen.digitalGold.title") }}</h1>
      <div class="row blocks-row">
        <div v-for="(block, i) in blocks" :key="i" class="col-12 col-md-6">
          <block
            :iconSrc="block.iconSrc"
            :title="$t(block.title)"
            :description="$t(block.description)"
          />
        </div>
      </div>
      <p class="blockText">{{ $t("bnoxScreen.digitalGold.blocks.text") }}</p>
    </div>
  </div>
</template>

<script>
import Block from "@/components/modules/bnox/Block";

export default {
  name: "DigitalGoldSection",
  props: {
    background: {
      type: String,
      required: false,
      default: "#F6F4FF"
    }
  },
  components: {
    Block
  },
  data: () => ({
    blocks: [
      {
        iconSrc: "/images/icons/bb-icon_cart-with-arrow.svg",
        title: "bnoxScreen.digitalGold.blocks.easyToComeBy.title",
        description: "bnoxScreen.digitalGold.blocks.easyToComeBy.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_flow.svg",
        title: "bnoxScreen.digitalGold.blocks.trade.title",
        description: "bnoxScreen.digitalGold.blocks.trade.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_percentage.svg",
        title: "bnoxScreen.digitalGold.blocks.lowFees.title",
        description: "bnoxScreen.digitalGold.blocks.lowFees.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_convert.svg",
        title: "bnoxScreen.digitalGold.blocks.exchangeable.title",
        description: "bnoxScreen.digitalGold.blocks.exchangeable.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_payment.svg",
        title: "bnoxScreen.digitalGold.blocks.value.title",
        description: "bnoxScreen.digitalGold.blocks.value.description"
      },
    ]
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.digital-gold-section {
  background: var(--background);
  padding: 40px 0;

  @media (max-width: $breakpoint-sm) {
    padding: 25px 0 30px;
  }
}

.title {
  color: $primary-purple;
  text-align: center;
  max-width: 730px;
  margin: 0 auto 50px;
}

.blockText {
  color: rgba(70, 102, 143, 1);
  font-size: 16px;
  margin-left: 60px;

  @media (max-width: $breakpoint-sm) {
    margin: 0;
    text-align: center;
  }
}
</style>