<template>
  <div class="gold-standard-section" :style="`--background: ${background}`">
    <div class="container">
      <h1 class="title headline-l">{{ $t('bnoxScreen.goldBased.title') }}</h1>
      <h4 class="subtitle">{{ $t('bnoxScreen.goldBased.subtitle') }}</h4>
      <div class="row blocks-row">
        <div v-for="(block, i ) in blocks" :key="i" class="col-12 col-md-6">
          <block
            :iconSrc="block.iconSrc"
            :title="$t(block.title)"
            :description="$t(block.description)"
          />
        </div>
      </div>
      <div class="row charts-row">
        <div class="col-12 col-md-6">
          <static-chart
            class="ml-auto comparison-chart"
            :title="$t('bnoxScreen.goldBased.charts.comparison.title')"
            linesSrc="/images/elements/charts/comparison-lines.svg"
            :scales="comparisonScales"
            :leftSideLabelText="$t('bnoxScreen.goldBased.charts.labels.gold')"
            :rightSideLabelText="$t('bnoxScreen.goldBased.charts.labels.sp500')"
          />
        </div>
        <div class="col-12 col-md-6">
          <static-chart
            :title="$t('bnoxScreen.goldBased.charts.recession.title')"
            linesSrc="/images/elements/charts/recession-lines.svg"
            :scales="recessionScales"
            :leftSideLabelText="$t('bnoxScreen.goldBased.charts.labels.gold')"
            :rightSideLabelText="$t('bnoxScreen.goldBased.charts.labels.sp500')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Block from "@/components/modules/bnox/Block";
import StaticChart from "@/components/modules/bnox/StaticChart";

export default {
  name: "GoldStandardSection",
  props: {
    background: {
      type: String,
      required: false,
      default: "#F6F4FF"
    }
  },
  components: {
    Block,
    StaticChart
  },
  data: () => ({
    blocks: [
      {
        iconSrc: "/images/icons/bb-icon_treasure.svg",
        title: "bnoxScreen.goldBased.blocks.history.title",
        description: "bnoxScreen.goldBased.blocks.history.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_gold-protection.svg",
        title: "bnoxScreen.goldBased.blocks.wealth.title",
        description: "bnoxScreen.goldBased.blocks.wealth.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_gold-lock.svg",
        title: "bnoxScreen.goldBased.blocks.savings.title",
        description: "bnoxScreen.goldBased.blocks.savings.description"
      },
      {
        iconSrc: "/images/icons/bb-icon_parts.svg",
        title: "bnoxScreen.goldBased.blocks.diversification.title",
        description: "bnoxScreen.goldBased.blocks.diversification.description"
      }
    ]
  }),
  created() {},
  computed: {
    comparisonScales() {
      return [
        "'70",
        "'80",
        "'90",
        "'00",
        "'10",
        "'20",
      ]
    },
    recessionScales() {
      return [
        this.$t("bnoxScreen.goldBased.charts.recession.scales.jan"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.feb"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.march"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.apr"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.maj"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.jun"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.jul"),
        this.$t("bnoxScreen.goldBased.charts.recession.scales.aug"),
      ]
    }
  },
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.gold-standard-section {
  background: var(--background);
  padding: 75px 0 40px;

  @media (max-width: $breakpoint-sm) {
    padding: 40px 0 25px; 
  }
}

.title {
  color: $primary-purple;
  text-align: center;
}

.subtitle {
  text-align: center;
  font-family: "HKNova-Bold", sans-serif;
  margin: 25px 0 85px;
}

.charts-row {
  margin-top: 30px;

  .col-12 {
    @media (max-width: $breakpoint-xs) {
      padding: 0;
    }
  }
}

.static-chart {
  max-width: 445px;

  @media (max-width: $breakpoint-sm) {
    margin: 0 auto;
    max-width: 100%;
  }

  @media (max-width: $breakpoint-xs) {
    &::v-deep {
      .chart-wrapper {
        border-radius: 0;
        border: 0;
      }
    }
  }

  &.comparison-chart {
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 70px;
    }
  }
}
</style>