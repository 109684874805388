<template>
  <div id="bnox-screen">
    <jumbotron
      :logoSrc="'/images/logos/bnox-short-logo.svg'"
      :title="$t('bnoxScreen.welcome.title')"
      :description="$t('bnoxScreen.welcome.description')"
      :storesLabel="$t('bnoxScreen.welcome.storesLabel')"
      :background="'linear-gradient(101.11deg, #EB5241 0%, #F59B12 105.24%, rgba(231, 47, 74, 0) 108.64%)'"
      :backgroundImage="'/images/sections/bnox/desktop/header_background.jpg'"
      :mobileBackgroundImage="'/images/sections/bnox/mobile/header_background.jpg'"
      :textColor="'#000'"
      :titleColor="'#000'"
    >
      <template v-slot:chart>
        <chart-component
          v-if="refactoredData"
          :titleText="$t('bnoxScreen.welcome.chart.title')"
          :subtitleText="$t('bnoxScreen.welcome.chart.subtitle', { value: calculatedLastPrice })"
          :infoText="$t('bnoxScreen.welcome.chart.infoText')"
          :chart-data="refactoredData"
          :gradientStartColor="'rgba(245, 154, 20, 0.4)'"
          :gradientEndColor="'rgba(245, 154, 20, 0)'"
          :infoTooltipBackground="'linear-gradient(101.23deg, #FCBC0D -2.31%, #F39665 104.94%)'"
        />
      </template>
    </jumbotron>
    <quote
      :quote="$t('bnoxScreen.quote.content')"
      :author="$t('bnoxScreen.quote.author')"
      :borderColor="'#E8A83B'"
    />
    <introduction-section />
    <gold-standard-section />
    <digital-gold-section />
    <bnox-trade-section />
    <partners-section />
    <why-bnox-table />
    <token-information-section />
    <mentions-section />
    <warning-section :backgroundColor="'#F6F4FF'" />
    <other-products hiddenProductKey="bnox" titleColor="#4B2EAA" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/api";
import moment from 'moment';
import ChartComponent from "@/components/elements/ChartComponent";
import Jumbotron from "@/components/elements/Jumbotron";
import Quote from "@/components/sections/Quote";
import IntroductionSection from "@/components/sections/bnox/IntroductionSection";
import GoldStandardSection from "@/components/sections/bnox/GoldStandardSection";
import DigitalGoldSection from "@/components/sections/bnox/DigitalGoldSection";
import BnoxTradeSection from "@/components/sections/bnox/BnoxTradeSection";
import PartnersSection from "@/components/sections/bnox/PartnersSection";
import WhyBnoxTable from "@/components/sections/bnox/WhyBnoxTable";
import TokenInformationSection from "@/components/sections/bnox/TokenInformationSection";
import MentionsSection from "@/components/sections/bnox/MentionsSection";
import OtherProducts from "@/components/sections/OtherProducts";
import WarningSection from "@/components/sections/bit/WarningSection";

export default {
  name: "BnoxScreen",
  props: {},
  components: {
    Jumbotron,
    Quote,
    IntroductionSection,
    GoldStandardSection,
    DigitalGoldSection,
    BnoxTradeSection,
    PartnersSection,
    WhyBnoxTable,
    TokenInformationSection,
    MentionsSection,
    OtherProducts,
    ChartComponent,
    WarningSection
  },
  data: () => ({
    chartDataLimit: 7,
    chartDataInterval: '1m',
    chartData: undefined,
  }),
  created() {},
  computed: {
    ...mapGetters({
      getSelectedLanguage: "settings/getSelectedLanguage",
    }),
    calculatedLastPrice() {
      if (this.chartData) {
        return (this.chartData[this.chartData.length - 1].rate * 1.005).toFixed(2);
      }
      return undefined;
    },
    refactoredData() {
      if (this.chartData) {
        return {
          labels: this.chartData.map((obj) => {
            moment.locale(this.getSelectedLanguage);
            const newDate = moment(new Date(obj.date)).format('MMM').replace('.', '');
            return newDate.toLowerCase();
          }),
          tooltipLabel: (value) => `€${value.yLabel.toFixed(2)} / 100 BNOx`,
          datasets: [
            {
              data: this.chartData.map((obj) => obj.rate * 1.005),
              borderColor: '#F59A14',
              pointBackgroundColor: 'rgba(0, 0, 0, 0)',
              pointBorderColor: 'rgba(0, 0, 0, 0)',
              borderCapStyle: 'round',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: '#4B2EAA',
              pointHighlightFill: "#fff",
              pointHoverBorderWidth: 2,
              backgroundColor: "transparent",
              pointColor : "#fff",
              pointStrokeColor : "#ff6c23",
            },
          ],
        };
      }
      return undefined;
    }
  },
  methods: {},
  async mounted() {
    const response = await Api.get(`publicReferencePrice/BNO?limit=${this.chartDataLimit}&interval=${this.chartDataInterval}`);
    this.chartData = await response.json();

    const res = await Api.get("publicPrice");
    this.currentPrice = await res.json();
    
    let currentProduct = this.currentPrice.find(price => {
      return price.asset === "BNO" && price.type === "Buy";
    });

    if(currentProduct){
      this.chartData[this.chartData.length - 1].rate = (currentProduct.rate * 1.005).toFixed(2);
    }
  },
};
</script>
 
<style lang='scss' scoped>
.jumbotron {
  &::v-deep {
    .logo {
      margin: 0 0 30px;
    }
  }
}

#chart-component {
  &::v-deep {
    .title-text {
      color: $primary-purple;
    }
  }
}

.warning-section {
  color: $text-blue;

  &::v-deep .icon-wrapper {
    img {
      filter: invert(34%) sepia(11%) saturate(1866%) hue-rotate(174deg) brightness(50%) contrast(87%);
    }
  }
}
</style>