<template>
  <div class="partners-section" :style="`--background: ${background}`">
    <div class="container">
      <h1 class="title headline-l">{{ $t("bnoxScreen.partners.title") }}</h1>
      <p class="description text-l">
        {{ $t("bnoxScreen.partners.description") }}
      </p>
      <ul class="partners-list">
        <li v-for="(partner, i) in partners" :key="i">
          <a :href="partner.link" target="_blank"><img :src="partner.logoSrc" /></a>
        </li>
      </ul>
      <span class="note text-l">{{ $t("bnoxScreen.partners.note") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersSection",
  props: {
    background: {
      type: String,
      required: false,
      default: "#F6F4FF",
    },
  },
  components: {},
  data: () => ({
    partners: [
      {
        link: "https://app.uniswap.org/",
        logoSrc: "/images/logos/uniswap-logo.svg",
      },
      {
        link: "https://coinmarketcap.com/currencies/blocknotex/",
        logoSrc: "/images/logos/coinmarketcap-logo.svg",
      },
      {
        link: "https://www.geckoterminal.com/eth/pools/0xe7fec0755190e6af9adfeb96d6531f30cca4c3db",
        logoSrc: "/images/logos/coingecko-logo.svg",
      },
    ],
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
.partners-section {
  background: var(--background);
  padding: 55px 0 75px;
}

.title {
  color: $primary-purple;
  margin: 0 0 35px;
  text-align: center;
}

.description {
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
}

.partners-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 60px 0 0;
  padding: 0;
  align-items: center;

  @media (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    margin: 45px 0 0;
  }

  li {
    margin: 0 110px 0 0;

    @media (max-width: $breakpoint-sm) {
      margin: 0 0 50px;
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }

    img {
      @media (max-width: $breakpoint-md) {
        margin: 0 auto;
      }
    }

    &:last-child {
      margin: 0;
    }
  }
}

.note {
  display: block;
  text-align: center;
  margin: 60px 0 0;
}
</style>